import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    //LangugaeSwitcher
    languageSwitcher:{
        marginBottom: "auto",
        marginTop: 20,
    },
    languageButton:{
        width: 40,
        cursor: "pointer",
        opacity: 0.5,
        "&:hover, &:focus, &$selected, &$selected:hover": {
            opacity: 1,
        }
    },
        selected:{
            width: 40,
            cursor: "pointer",
            opacity: 1,
        },
}),{defaultTheme: theme});

export { useStyles };