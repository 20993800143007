//SOURCE: https://www.freecodecamp.org/news/how-to-add-localization-to-your-react-app/
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../styles/LanguageSwitcherStyles';

import CZ from '../../static/images/languages/czech-republic.svg';
import EN from '../../static/images/languages/united-kingdom.svg';
 

export default function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const language = i18n.language;

  function changeLanguage(value) {
    i18n.changeLanguage(value);
  }

  return (
    <div className={classes.languageSwitcher}>
      <img onClick={()=>changeLanguage('cs')} className={language=='cs' ? classes.selected : classes.languageButton} src={CZ} alt={t("CZECH")}/>
      <img onClick={()=>changeLanguage('en')} className={language=='en' ? classes.selected : classes.languageButton} src={EN} alt={t("ENGLISH")}/>
    </div>
  );
};